import React, {useRef, useState} from 'react';
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";

interface ITermsAndConditions {

}

const TermsAndConditions: React.FC<ITermsAndConditions> = (props) => {
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const termsAndConditionsContainerRef = useRef<HTMLDivElement | null>(null);

    return (
        <div className="App">
            <CommCommHeader
                showHamburgerMenu={showHamburgerMenu}
                setShowHamburgerMenu={setShowHamburgerMenu}
                htmlRef={termsAndConditionsContainerRef}
            />
            <div className="terms-and-conditions-container" ref={termsAndConditionsContainerRef}>
                <div className='terms-and-conditions-content-wrapper'>
                    <h1>CommComm Terms & Conditions of Use with Disclosures & Disclaimers</h1>
                    <p>By visiting and using this website, you agree that you have read, understood, and agree to the
                        following Terms and Conditions of Use by Comm Comm Corp.</p>
                    <br/>
                    <h2>1. Intellectual Property</h2>
                    <p>This website includes materials protected by intellectual property laws, including without
                        limitation written text, logos, photos, videos, music, art, designs, and/or graphics.</p>
                    <p>You may view and use materials from this website for personal, noncommercial purposes only. Any
                        reproduction or unauthorized use of any materials found on this website shall constitute
                        infringement.</p>
                    <br/>
                    <h2>2. Visitor Comments and Posts</h2>
                    <p>If you submit, comment or post any materials (such as photos, videos, or written content) to this
                        website,
                        you represent and warrant that:</p>
                    <div className='indent'>
                        <p>(1) you are at least 18 years old;</p>
                        <p>(2) you are the owner of or have permission to share such materials;</p>
                        <p>(3) you grant CommComm a perpetual, royalty-free, worldwide non-exclusive license to use,
                            copy,
                            reproduce, publish, distribute, display and publicly perform those materials, in whole or
                            part, in any manner or medium,
                            now known or hereafter developed, for any purpose, including commercial purposes and
                            advertising;</p>
                        <p>(4) you grant CommComm
                            a perpetual, royalty-free, worldwide non-exclusive license and release to use your name and
                            likeness in connection with
                            such materials for any purposes, including commercial purposes and advertising. CommComm
                            does not claim any ownership rights
                            in your materials.</p>
                    </div>
                    <p>Please choose carefully the materials/photos that you submit on this website. Any material and
                        photo you post
                        on this website becomes public. You are responsible for your material and photo and for any
                        liability that may
                        result from the material and photo you post on this website. You participate, comment, and post
                        material and photos on this website at your own risk.
                        Any communication by you on this website, whether by sending in-website messages, contact
                        submission form, or other interactive
                        service, must be respectful. You may not communicate or submit any content, photo, or material
                        that is abusive, vulgar, threatening,
                        harassing, knowingly false, defamatory or obscene, or otherwise in violation of any law or the
                        rights of others. You agree to post
                        comments, photos, or other material only one time.</p>
                    <p>CommComm, in its discretion, may delete or modify, in whole or part, any post, comment, or
                        submission to this website.
                        CommComm does not, however, have any obligation to monitor posts, comments, or material
                        submitted by third parties. CommComm neither
                        endorses nor makes any representations as to the truthfulness or validity of any third-party
                        posts, comments, photos, or
                        material on this website. CommComm shall not be responsible or liable for any loss or damage
                        caused by third-party posts,
                        comments, photos, or materials on this website.</p>
                    <br/>
                    <h2>3. Visitor Behavior on Website</h2>
                    <p>You understand and hereby acknowledge and agree that you are strictly forbidden from the
                        following:</p>
                    <ul>
                        <li>Use CommComm or any location information displayed within CommComm to “stalk” harass, abuse,
                            defame, threaten or defraud other Users, or collect, attempt to collect, or store location
                            or personal information about other Users.
                        </li>
                        <li>Include offensive or pornographic material or materials that are harmful to minors in your
                            CommComm profile.
                        </li>
                        <li>Impersonate any person or entity, falsely claim an affiliation with any person or entity, or
                            access the CommComm accounts of other users.
                        </li>
                        <li>Post, store, send, transmit or disseminate any information or material which a reasonable
                            person could deem to be objectionable, defamatory, libelous, offensive, obscene, indecent,
                            pornographic, harassing, threatening, embarrassing, distressing, vulgar, hateful, racially
                            or ethnically or otherwise offensive to any group or individual, intentionally misleading,
                            false or otherwise inappropriate, regardless of whether this material or its dissemination
                            is unlawful.
                        </li>
                        <li>Post, store, send, transmit or disseminate any information of materials which infringes any
                            patents, trademarks, trade secrets copyrights, or any other proprietary or intellectual
                            property rights.
                        </li>
                        <li>{`Intentionally interfering with or causing damage to this website or any user's enjoyment of them, by any means.`}</li>
                        <li>Using this website for any unlawful, illegal, fraudulent, or harmful purpose or activity.
                        </li>
                        <li>Using this website to copy, store, host, transmit, send, use, publish or distribute any
                            spyware, virus, worm, Trojan horse, keystroke logger, or other malicious software.
                        </li>
                        <li>Using this website to transmit, send, or deliver unsolicited communications or for other
                            marketing or advertising purposes.
                        </li>
                        <li>Systematically or automatically collecting data from this website.</li>
                    </ul>
                    <p>CommComm may, without notice, refuse access to its website, in whole or part, to any person that
                        fails to comply with these Terms.</p>
                    <br/>
                    <h2>4. DISCLAIMERS</h2>
                    <p>This website provides information only and does not provide any financial, legal, medical, or
                        psychological services or advice.
                        None of the content on this website prevents, cures, or treats any mental or medical condition.
                        You are responsible for your own
                        financial, legal, physical, mental, and emotional well-being, decisions, choices, actions, and
                        results. CommComm disclaims any liability for
                        your reliance on any opinions or advice contained in this website.</p>
                    <p>This website is not intended to be a substitute for professional advice that can be provided by
                        your own accountant, lawyer,
                        financial advisor, or medical professional. You should consult with a professional if you have
                        specific questions about your own unique
                        situation.</p>
                    <br/>
                    <h2>5. IMPORTANT RESULTS DISCLAIMER - NO GUARANTEE OF RESULTS</h2>
                    <p>You agree that CommComm has not made and does not make any specific representations about the
                        results you may receive. CommComm cannot and does
                        not guarantee that you will achieve any particular result from your use of the website, and you
                        understand that results differ for
                        each individual.</p>
                    <br/>
                    <h2>6. Links to Third-Party Products, Services, or Sites</h2>
                    <p>Any links to third-party products, services, or sites are subject to separate terms and
                        conditions. CommComm is not responsible for or liable
                        for any content on or actions taken by such third-party company or website. Although CommComm
                        may recommend third-party sites, products, or services,
                        it is your responsibility to fully research such third parties before entering into any
                        transaction or relationship with them.</p>
                    <br/>
                    <h2>7. No Warranty</h2>
                    <p>THE INFORMATION, PRODUCTS AND SERVICES OFFERED ON OR THROUGH THIS WEBSITE ARE PROVIDED “AS IS”
                        AND WITHOUT WARRANTIES OF ANY KIND
                        EITHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, COMMCOMM
                        DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
                        BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY FOR A PARTICULAR PURPOSE. COMMCOMM
                        DOES NOT WARRANT THAT THIS WEBSITE OR ANY OF
                        ITS FUNCTIONS WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT ANY
                        PART OF THIS WEBSITE, OR THE SERVERS THAT
                        MAKE IT AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
                    <p>COMMCOMM SHALL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
                        PUNITIVE DAMAGES THAT RESULT FROM THE USE OF, OR THE
                        INABILITY TO USE, THIS WEBSITE, INCLUDING ITS MATERIALS, PRODUCTS OR SERVICES, OR THIRD-PARTY
                        MATERIALS, PRODUCTS OR SERVICES MADE AVAILABLE
                        THROUGH THIS WEBSITE.</p>
                    <br/>
                    <h2>8. General Terms</h2>
                    <p>These Terms shall be governed by and construed in accordance with the laws of the State of
                        Florida, without giving effect to its conflict of laws.
                        The nearest state and federal court to Davie, Florida shall have exclusive jurisdiction over any
                        case or controversy arising from or relating to this
                        website, including but not limited to the Privacy Policy or these Terms. By using this website,
                        you hereby submit to the exclusive
                        jurisdiction and venue of these courts and consent irrevocably to personal jurisdiction in such
                        courts and waive any defense of forum non conveniens.</p>
                    <p>{`If any provision of these Terms is held invalid or unenforceable, the remainder of these Terms will remain in full force and the invalid or
                    unenforceable provision will be replaced by a valid or enforceable provision. The prevailing party in any dispute between the parties arising out of
                    or related to these Terms, whether resolved by negotiation, mediation, or litigation, shall be entitled to recover its attorneys' fees and costs from
                    the other party.`}</p>
                    <p>This is the entire agreement of the parties, and reflects a complete understanding of the parties
                        with respect to the subject matter. This agreement
                        supersedes all prior written and oral representations.</p>
                    <p>CommComm may change, modify, or update these Terms at any time without notice. Any access or use
                        of this website by you after CommComm posts such
                        changes shall constitute consent of such modifications. If you have any questions or concerns
                        about these Terms, contact <b><a href="mailto:info@commcomm.app"
                                                         className='hyperlink'>info@commcomm.app</a></b>.</p>
                    <p>CommComm controls and operates this website from an office in the United States. CommComm does
                        not represent that materials and photos on this
                        website are appropriate or available for use in other locations. People who choose to access
                        this website from other locations do so on
                        their own initiative and are responsible for compliance with local laws, if and to the extent
                        local laws are applicable.</p>
                    <br/>
                    <h2>9. End User Licenses</h2>
                    <ul>
                        <li><b>License Grant.</b> Subject to the terms of this Agreement, CommComm hereby grants you
                            non-exclusive, non-transferable, revocable license to (i) use a
                            compiled code copy of the CommComm software for an CommComm services account on one mobile
                            device owned or leased solely by you, for your personal,
                            noncommercial use and (ii) use the CommComm Services for your personal, noncommercial use.
                        </li>
                        <li><b>Restrictions.</b> You may not:
                            <div className='indent' style={{marginTop: "15px"}}>
                                <p>(i) modify, disassemble, decompile or reverse engineer the CommComm Software, except
                                    to the extent that such restriction
                                    is expressly prohibited by law;</p>
                                <p>(ii) rent, lease, loan, resell, sublicense, distribute or otherwise transfer the
                                    CommComm Software to any third party or use
                                    the CommComm Software to provide time sharing or similar services for any third
                                    party;
                                    (iii) make any copies of the CommComm Software;</p>
                                <p>(iv) remove, circumvent, disable, damage or otherwise interfere with security-related
                                    features of the CommComm Software, features that prevent or restrict
                                    use or copying of any content accessible through the CommComm Software, or features
                                    that enforce limitations on use of the CommComm Software; or</p>
                                <p>(v) delete the copyright and other proprietary rights notices on the CommComm
                                    Software.</p>
                            </div>
                        </li>
                        <li><b>Software Upgrades.</b> You acknowledge that CommComm may from time to time issue upgraded
                            versions of the CommComm Software, and may automatically electronically
                            upgrade the version of the CommComm Software that you are using on your mobile device. <b>You
                                consent to such automatic upgrading on your mobile device, and agree that
                                the terms and conditions of this Agreement will apply to all such upgrades.</b> You
                            agree that CommComm will not be liable to you for any such modifications.
                        </li>
                        <li><b>Rights Reserved.</b> The foregoing license grant under this Agreement is not a sale of
                            the CommComm Software or any copy thereof and CommComm or its third-party partners
                            or suppliers retain all right, title, and interest in the CommComm Software (and any copy
                            thereof). Any attempt by you to transfer any of the rights, duties or obligations
                            hereunder, except as expressly provided for in this Agreement, is void. CommComm reserves
                            all rights not expressly granted under this Agreement.
                        </li>
                        <li><b>Trademarks, Service Marks and Logos.</b> The name of this web site is a service mark of
                            CommComm. No use of this mark shall be permitted except through the prior written
                            authorization and permission of CommComm. All rights reserved.
                        </li>
                        <li><b>Export Control.</b> The CommComm Software originates in the United States, and is subject
                            to United States export laws and regulations. The CommComm Software may not be
                            exported or re-exported to certain countries or those persons or entities prohibited from
                            receiving exports from the United States. In addition, the CommComm Software may
                            be subject to the import and export laws of other countries. You agree to comply with all
                            United States and foreign laws related to use of the CommComm Software and the
                            CommComm Services.
                        </li>
                        <li><b>App Stores.</b> You acknowledge and agree that the availability of the CommComm Software
                            is dependent on the third party from which you received the CommComm Software,
                            e.g., the Android Market or Apple app store (each, an “<b>App Store</b>”). You acknowledge
                            that this Agreement is between you and CommComm and not with the App Store.
                            Each App Store may have its own terms and conditions to which you must agree before
                            downloading the CommComm Software from it. You agree to comply with, and your license
                            to use the CommComm Software is conditioned upon your compliance with, all applicable terms
                            and conditions of the applicable App Store.
                        </li>
                    </ul>
                    <br/>
                    <h2>10. Violations; Termination</h2>
                    <p>You agree that CommComm, in the good faith belief that you have violated any of the terms and
                        conditions of this Agreement, may terminate any account you have with the CommComm
                        Services or your use of the CommComm Services or any portion thereof and remove and discard all
                        or any part of your account or any User Submission, at any time. You agree that
                        any termination of your access to the CommComm Services or any account you may have or portion
                        thereof may be effected without prior notice, and you agree that CommComm will not
                        be liable to you or any third-party for any such termination. CommComm does not permit copyright
                        infringing activities on the CommComm Services, and reserves the right to
                        terminate access to the CommComm Services, and remove all content submitted, by any persons who
                        are found to be repeat infringers. Any suspected fraudulent, abusive or illegal
                        activity that may be grounds for termination of your use of the Services may be referred to
                        appropriate law enforcement authorities. These remedies are in addition to any
                        other remedies CommComm may have at law or in equity.</p>
                    <br/>
                    <p className="update-text">Updated on November 16th, 2023</p>
                </div>
                <CommCommFooter/>
            </div>
        </div>
    );
};

export default TermsAndConditions;