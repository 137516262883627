import React from "react";
import {Modal} from "react-bootstrap";
import {IoWarning} from "react-icons/io5";
import {AccountData} from "../../../data/AccountData";
import {loadStripe} from "@stripe/stripe-js";
import {config} from "../../../config/config";


interface IModalRedirectToStripeMessage {
    account: AccountData;
    isOpen: boolean;
    toggleShow: (show: boolean) => void;
    redirectUrl: string;
    cancel: () => void;
}

export const ModalRedirectToStripeMessage: React.FunctionComponent<IModalRedirectToStripeMessage> = (props) => {
    const redirect = () => {
        if (props.account.planId === "") {
            loadStripe(config.publishableKey)
                .then(stripe => {
                    if (!stripe) {
                        throw new Error('Stripe is not loaded');
                    }
                    stripe.redirectToCheckout({
                        sessionId: props.redirectUrl,
                    });
                })
        } else {
            window.location.href = props.redirectUrl;
        }

        props.cancel();
    }

    return (
        <div>
            <Modal
                show={props.isOpen}
                onHide={() => props.toggleShow(!props.isOpen)}
            >
                <div className={"modalWarningMessageBackground"}>
                    <Modal.Header closeButton aria-label="Hide" closeVariant="white">
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalWarningMessageContentWrapper">
                            <div className="modalMessageRow">
                                <IoWarning style={{color: "#FFC722", width: "280px"}} size={130}/>
                                <div className='modalWarningMessageColumn'>
                                    <div className="modalWarningMessageHeader">Payment Portal Redirect</div>
                                    <p>You are about to redirected to a Stripe page. Hit <span>{`"confirm"`}</span> if
                                        you wish to proceed or <span>{`"cancel"`}</span> to remain on Comm Comm.</p>
                                </div>
                            </div>
                            <div className="modalMessageRow">
                                <button className='secondaryBtn' type="button"
                                        onClick={props.cancel}>Cancel
                                </button>
                                <button className={props.redirectUrl ? 'blueBtn' : 'disabledBtn'}
                                        disabled={props.redirectUrl === null} type="button" onClick={() => redirect()}
                                        form='stripe'>Confirm
                                </button>
                            </div>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    );
}