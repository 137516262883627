import React, {useEffect, useRef, useState} from 'react';
import {signInWithPopup} from "firebase/auth";
import {auth, Providers} from '../config/Firebase';
import {useNavigate, useSearchParams} from "react-router-dom";
import AccountDataSource from "../api/AccountDataSource";
import CommCommPeople from '../assets/CommComm-People.png';
import GoogleLogo from '../assets/Google-Logo.png';
import {navPath} from "../components/Helpers";
import UseToken from "../context/UseToken";
import CommCommHeader from "../components/Home/CommCommHeader";
import CommCommFooter from "../components/Home/CommCommFooter";
import LoadingOverlay from "../components/Home/LoadingOverlay";
import {UserAuth} from "../context/AuthContextProvider";

//https://stackoverflow.com/questions/53071774/reactjs-delay-onchange-while-typing
export interface ILoginPageProps {
    setLoggedIn: (isLoggedIn: boolean) => void;
    loginTab: boolean;
}

//consult with chatGPT inregards to when signout should occur
const LoginSignUp: React.FunctionComponent<ILoginPageProps> = (props) => {
    const navigate = useNavigate();
    const {user} = UserAuth();
    const [searchParams] = useSearchParams();
    const refCode = searchParams.get('refCode');
    const [authenticating, setAuthenticating] = useState(false);
    const [selectedSignInTab, setSelectedSignInTab] = useState(props.loginTab);
    const [selectedSignUpTab, setSelectedSignUpTab] = useState(!props.loginTab);
    const [username, setUsername] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
    const loginSignupContainerRef = useRef<HTMLDivElement | null>(null);
    const [accountExists, setAccountExists] = useState(false);
    const [accountDoesNotExist, setAccountDoesNotExist] = useState(true);
    const [validating, setValidating] = useState(false);
    const [validUsername, setValidUsername] = useState(false);
    const [validFirstName, setValidFirstName] = useState(false);
    const [validLastName, setValidLastName] = useState(false);
    const [validReferralCode, setValidReferralCode] = useState(referralCode?.length === 0);

    //http://localhost:3000/login?refCode=123456789
    useEffect(() => {
        //if referral url is used then verify refCode and place it in ref input box
        if (refCode != null && new RegExp(/^[A-Za-z0-9_.-]+$/gm).test(refCode)) {
            setSelectedSignUpTab(true)
            setSelectedSignInTab(false)
            setReferralCode(refCode)
        }
    }, [refCode])

    useEffect(() => {
        if (user) {
            if (!authenticating) {
                setAuthenticating(true);
                AccountDataSource.Get.fromEmail(user?.email ?? "").then(() => {
                    // user.getIdToken().then((newToken) => {
                    //     UseToken().setToken(newToken)
                    // })
                    setTimeout(function () {
                        setAuthenticating(false);
                        navigate(navPath.projects, {replace: true});
                    }, 2200);
                    props.setLoggedIn(true);
                }).catch((error) => {
                    setAuthenticating(false);
                    auth.signOut()
                })
            }
        }
    }, [authenticating])

    useEffect(() => {
        if (new RegExp(/^[A-Za-z0-9_.-]+$/gm).test(username) && username.length > 0) {
            setValidUsername(true)
            setValidating(true);
            // Set a new timeout to wait for the user to stop typing
            const timeOutId = setTimeout(() => AccountDataSource.Get.verifyUsername(username).then((response) => {
                setAccountExists(response)
                setValidating(false)
            }), 1500);
            // Cleanup function to clear the timeout if the component unmounts
            return () => clearTimeout(timeOutId);
        } else {
            setValidUsername(false)
        }
    }, [username]);

    //add regex to username

    const changeToSignIn = () => {
        setSelectedSignUpTab(false)
        setSelectedSignInTab(true)
    }

    const changeToSignUp = () => {
        setSelectedSignUpTab(true)
        setSelectedSignInTab(false)
    }

    //LOG IN
    const signInWithGoogle = async () => {
        setAuthenticating(true);
        signInWithPopup(auth, Providers.google)
            .then((cred) => {
                AccountDataSource.Get.fromEmail(cred.user?.email ?? "").then(() => {
                    cred.user.getIdToken().then((newToken) => {
                        UseToken().setToken(newToken)
                    })
                    navigate(navPath.projects, {replace: true});
                }).catch((error) => {
                    setAccountDoesNotExist(false);
                    // display accound not existing error
                    auth.signOut()
                })
            })
            .catch((error) => {
                auth.signOut()
                setAccountDoesNotExist(false);
                // setError('Unable to sign in');
            })
            .finally(() => {
                setAuthenticating(false);
            })
    }

    //SIGN UP
    const signUpWithGoogle = async () => {
        setAuthenticating(true);
        signInWithPopup(auth, Providers.google)
            .then((cred) => {
                AccountDataSource.Post.create(cred.user?.email ?? "", username, firstName, lastName, referralCode ?? '').then((response) => {
                    cred.user.getIdToken().then((newToken) => {
                        UseToken().setToken(newToken)
                    })
                    UseToken().setRefreshToken(cred.user.refreshToken);
                }).finally(() => {
                    setAuthenticating(false);
                })
            })
            .catch((error) => {
                setAuthenticating(false);
                // setError('Unable to sign in');
            }).finally(() => {
            setAccountDoesNotExist(true);
        })
    }

    const validateFirstName = (name: string) => {
        setFirstName(name);
        if (new RegExp(/^[A-Za-z. -]+$/gm).test(name) && name.length > 0) {
            setValidFirstName(true);
        } else {
            setValidFirstName(false);
        }
    }

    const validateLastName = (name: string) => {
        setLastName(name);
        if (new RegExp(/^[A-Za-z. -]+$/gm).test(name) && name.length > 0) {
            setValidLastName(true);
        } else {
            setValidLastName(false);
        }
    }

    const validateReferralCode = (code: string) => {
        setReferralCode(code);
        if (new RegExp(/^[A-Za-z0-9_.-]+$/gm).test(code) || code.length === 0) {
            setValidReferralCode(true);
        } else {
            setValidReferralCode(false);
        }
    }


    return (
        <>
            <div className="App">
                <CommCommHeader
                    showHamburgerMenu={showHamburgerMenu}
                    setShowHamburgerMenu={setShowHamburgerMenu}
                    htmlRef={loginSignupContainerRef}
                />
                <div className='login-page-container' ref={loginSignupContainerRef}>
                    <div className='loginStandardRow'>
                        <div className='loginColumn'>
                            <div className='loginSignUpContainer'>
                                <div className="toggleSlider">
                                    <input type="radio" id="sign-in" name="tabs" checked={selectedSignInTab}
                                           onChange={() => changeToSignIn()}/>
                                    <label className="tab" htmlFor="sign-in">Log In</label>
                                    <input type="radio" id="sign-up" name="tabs" checked={selectedSignUpTab}
                                           onChange={() => changeToSignUp()}/>
                                    <label className="tab" htmlFor="sign-up">Sign Up</label>
                                    <span className="glider"></span>
                                </div>
                                {selectedSignInTab && <div className="toggle-slider-body">
                                    <div className='signInUpText'>
                                        <h1>Welcome Back</h1>
                                        <p>{`Log in with Google if you're an existing user.`}</p>
                                    </div>
                                    <button className='loginGoogleBtn' type="button" onClick={() => signInWithGoogle()}
                                            disabled={authenticating}><img className='googleLogo' src={GoogleLogo}/>Log
                                        in
                                        with
                                        Google
                                    </button>
                                    {/*<ErrorText error={error} />*/}
                                    <p className='signInUpText' style={{marginBottom: "0px"}}>No account? Toggle the
                                        button to sign up!</p>
                                    <p hidden={accountDoesNotExist} style={{color: "red"}}><b>Account does not exist.
                                        Please
                                        sign up.</b></p>
                                </div>}
                                {selectedSignUpTab && <div className="toggle-slider--body">
                                    <div className='signInUpText'>
                                        <h1>Create Account</h1>
                                        <p>Enter your first name, last name, and a username to get started.</p>
                                    </div>
                                    <div className='firstLastNameRow'>
                                        <div className="realNameColumn">
                                            <label className="signUpLabels"
                                                   htmlFor='commCommFirstName'>
                                                First Name
                                            </label>
                                            <input className="signUpInput"
                                                   id='commCommFirstName'
                                                   type='text'
                                                   onChange={(e) => validateFirstName(e.target.value)}
                                            />
                                        </div>
                                        <div className="realNameColumn">
                                            <label className="signUpLabels"
                                                   htmlFor='commCommLastName'>
                                                Last Name
                                            </label>
                                            <input className="signUpInput"
                                                   id='commCommLastName'
                                                   type='text'
                                                   onChange={(e) => validateLastName(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="usernameColumn">
                                        <label className="signUpLabels"
                                               style={{color: (accountExists && username.length > 0) ? "red" : "black"}}
                                               htmlFor='commmCommUsername'>
                                            Username
                                        </label>
                                        <input className="signUpInput"
                                               id='commCommUsername'
                                               type='text'
                                               onChange={(e) => setUsername(e.target.value)}
                                        />
                                        <div hidden={!(accountExists && username.length > 0)}
                                             style={{color: "red"}}>Username
                                            already exists! Choose another.
                                        </div>
                                    </div>
                                    <div className="usernameColumn">
                                        <label className="signUpLabels"
                                               style={{color: validReferralCode ? "black" : "red"}}
                                               htmlFor='commCommReferralCode'>
                                            Referral Code
                                        </label>
                                        <input className="signUpInput"
                                               id='commCommReferralCode'
                                               value={referralCode}
                                               type='text'
                                               onChange={(e) => validateReferralCode(e.target.value)}
                                        />
                                    </div>
                                    <button className='loginGoogleBtn'
                                            style={{backgroundColor: (!validLastName || !validFirstName || accountExists || !validUsername || !validReferralCode || validating) ? "#5118634F" : "#511863"}}
                                            onClick={() => signUpWithGoogle()}
                                            type="button"
                                            disabled={!validLastName || !validFirstName || accountExists || !validUsername || !validReferralCode || validating}>
                                        <img
                                            className='googleLogo' src={GoogleLogo}/>Sign up with
                                        Google
                                    </button>
                                    {/* <p className='fieldNote' style={{marginTop: "15px"}}>By signing up, you agree to our Terms of Service, Privacy Policy, and Cookies Policy.</p> */}
                                    {/*<ErrorText error={error} />*/}
                                </div>}
                            </div>
                        </div>

                        <div className='detailsColumn'>
                            <div className='textDetailColumn'>
                                <h1>Don’t leave your clients in the dark.</h1>
                                <p>Start building transparency and trust with your clients by showing your work progress
                                    with
                                    Comm Comm!</p>
                            </div>
                            <img className='peopleImg' src={CommCommPeople}/>
                        </div>
                    </div>
                    <CommCommFooter/>
                </div>
            </div>
            <LoadingOverlay isLoading={authenticating}/>
        </>);

};

export default LoginSignUp