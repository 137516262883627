import React, {useEffect, useRef, useState} from 'react';
import {Form, Modal} from "react-bootstrap";
import {ImageUploadService} from "../services/ImageUploadService";
import {getAuth} from "firebase/auth";
import {TbCameraPlus} from "react-icons/tb";
import {AccountData} from "../data/AccountData";
import LoadingOverlay from "../components/Home/LoadingOverlay";
import Zorua from '../assets/zorua-profile-pic.jpeg';
import SaveConfirmationToast from "../components/Base/SaveConfirmationToast";
import IconWithTooltip from "../components/Home/IconWithTooltip";
import ComponentLoading from "../components/Base/Popup/ComponentLoading";
import {referralLink} from "../components/Helpers";

interface IEditProfile {
    firstName: string;
    lastName: string;
    biography: string;
    image: string;
    email: string;
    username: string;
    phoneNumber: string;
    shopLink: string;
    socialMedia1: string;
    socialMedia2: string;
    socialMedia3: string;
    venmo: string;
    saveAccount: (acct: AccountData) => void;
    isAccountLoading: boolean;
    displayRealName: boolean;
    accountUpdateConfirmation: string;
    accountIsInactive: boolean;
    isSideNavVisible: boolean;
    submitDeactivationFeedback: (comment: string, reason: number) => void;
    submitReactivation: () => void;
    isProfileSaving: boolean;

    // onSave: (name: string, age: number, bio: string, profilePic: string) => void;
}

const EditProfile: React.FC<IEditProfile> = (props) => {
    const [file, setFile] = useState<any>(undefined);
    const [profileUrl, setProfileUrl] = useState(props.image)
    const [firstName, setFirstName] = useState(props.firstName);
    const [lastName, setLastName] = useState(props.lastName);
    const [shopLink, setShopLink] = useState(props.shopLink);
    const [bio, setBio] = useState(props.biography);
    const [socialMedia1, setSocialMedia1] = useState(props.socialMedia1);
    const [socialMedia2, setSocialMedia2] = useState(props.socialMedia2);
    const [socialMedia3, setSocialMedia3] = useState(props.socialMedia3);
    const [email] = useState(props.email);
    const [username] = useState(props.username);
    const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
    const [venmoHandle, setVenmoHandle] = useState(props.venmo);
    const [useDefaultImage, setUseDefaultImage] = useState(true);
    const [useRealName, setUseRealName] = useState(props.displayRealName);
    const [accountUpdated, setAccountUpdated] = useState(false);
    const [accountUpdatedSuccessful, setAccountUpdatedSuccessful] = useState(false);
    const [accountDeactivationSuccessful, setAccountDeactivationSuccessful] = useState(false);
    const [validFirstName, setValidFirstName] = useState(true);
    const [validLastName, setValidLastName] = useState(true);
    const [showDeactivationModal, setDeactivationModal] = useState(false);
    const [deactivationFeedbackType, setDeactivationFeedbackType] = useState("");
    const [deactivationComment, setDeactivationComment] = useState("")
    const copyReferralLinkRef = useRef<HTMLInputElement>(null);
    const auth = getAuth();

    useEffect(() => {
        setUseDefaultImage(props.image === null || props.image === "")
    }, [props.image])

    useEffect(() => {
        if (accountUpdated) {
            setAccountUpdated(false);
            setAccountUpdatedSuccessful(true);
            setTimeout(function () {
                setAccountUpdatedSuccessful(false);
            }, 4000);
        }
    }, [props.accountUpdateConfirmation]);

    useEffect(() => {
        if (accountUpdated) {
            setAccountUpdated(false);
            setAccountDeactivationSuccessful(true);
            setTimeout(function () {
                setAccountDeactivationSuccessful(false);
            }, 4000);
        }
    }, [props.accountIsInactive]);

    const updateAccount = (image: string | null) => {
        setAccountUpdated(true);
        props.saveAccount(new AccountData({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            biography: bio.trim(),
            shopLink: shopLink.trim(),
            image: image?.trim() ?? profileUrl,
            phoneNumber: phoneNumber.trim(),
            socialMedia1: socialMedia1.trim(),
            socialMedia2: socialMedia2.trim(),
            socialMedia3: socialMedia3.trim(),
            venmoHandle: venmoHandle.trim(),
            username: props.username,
            displayRealName: useRealName
        }))
    }

    const handleChange = (e: any) => {
        if (e.target.files[0] !== undefined) {
            setProfileUrl(URL.createObjectURL(e.target.files[0]))
            setUseDefaultImage(false);
            const {files} = e.target;
            const selectedFiles = files as FileList;
            setFile(selectedFiles?.[0]);
        }
    }

    //upload image
    const upload = () => {
        if (file === null || file === undefined) {
            updateAccount(null)
        } else {
            let path = `${auth.currentUser?.uid}/profile/profile_pic`;
            ImageUploadService({file, path, setProfileUrl, updateAcct: updateAccount});
        }
    }

    const save = () => {
        if (props.firstName !== firstName ||
            props.lastName !== lastName ||
            props.biography !== bio ||
            props.shopLink !== shopLink ||
            props.socialMedia1 !== socialMedia1 ||
            props.socialMedia2 !== socialMedia2 ||
            props.socialMedia3 !== socialMedia3 ||
            props.phoneNumber !== phoneNumber ||
            props.venmo !== venmoHandle ||
            props.displayRealName !== useRealName ||
            file !== undefined) {
            upload()
        }
    }

    const toggleProfileSavedConfirmation = () => setAccountUpdatedSuccessful(!accountUpdatedSuccessful);
    const toggleDeactivationConfirmation = () => setAccountDeactivationSuccessful(!accountDeactivationSuccessful);

    const handleImageError = () => {
        // Handle the image loading error here, e.g., set a state variable to indicate the error.
        setUseDefaultImage(false);
    };

    const changeDisplayRealName = () => {
        setUseRealName(!useRealName)
    }

    const handleFirstName = (input: string) => {
        setFirstName(input);
        if (new RegExp(/^.{1,51}$/).test(input)) {
            setValidFirstName(true);
        } else {
            setValidFirstName(false);
        }
    };

    const handleLastName = (input: string) => {
        setLastName(input);
        if (new RegExp(/^.{1,51}$/).test(input)) {
            setValidLastName(true);
        } else {
            setValidLastName(false);
        }
    };

    const handleDeactivationSurveyShow = () => {
        setDeactivationModal(true);
    };

    const handleDeactivationSurveyClose = () => {
        if (deactivationFeedbackType !== "" && deactivationComment.trim() !== "") {
            setAccountUpdated(true);
            props.submitDeactivationFeedback(deactivationComment.trim(), Number(deactivationFeedbackType));
            setDeactivationModal(false);
        }
    };

    const handleReactivation = () => {
        setAccountUpdated(true);
        props.submitReactivation();
    };

    const handleCopyLink = () => {
        if (copyReferralLinkRef.current) {
            copyReferralLinkRef.current.select();
            try {
                navigator.clipboard.writeText(copyReferralLinkRef.current.value);
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
            }
        }
    };

    return (
        <>
            <div className="App">
                <div className='editProfileImgBg'>
                    <div className="edit-profile-wrapper" style={{
                        marginLeft: props.isSideNavVisible ? '260px' : '70px',
                        transition: 'margin 0.3s ease-in-out'
                    }}>
                        <SaveConfirmationToast
                            confirmationText="Your profile has been saved!"
                            toggleUpdateConfirmation={toggleProfileSavedConfirmation}
                            updateConfirmation={accountUpdatedSuccessful}
                        />

                        <SaveConfirmationToast
                            confirmationText={props.accountIsInactive ? "Your account has been deactivated!" : "Your account has been reactivated!"}
                            toggleUpdateConfirmation={toggleDeactivationConfirmation}
                            updateConfirmation={accountDeactivationSuccessful}
                        />
                        {/*<Toast show={accountUpdatedSuccessful} onClose={toggleProfileSavedConfirmation}>*/}
                        {/*    <Toast.Header closeButton aria-label="Hide" closeVariant="white">*/}
                        {/*        <BsFillCheckCircleFill size={23} color={colorPicker.confirmationGreen}/>*/}
                        {/*        <strong className="savedConfirmationText">Your profile has been saved!</strong>*/}
                        {/*    </Toast.Header>*/}
                        {/*</Toast>*/}
                        <div className="edit-profile-background-container">
                            <div className="edit-profile-header">
                                <h1>My Profile</h1>
                                <div className="profile-image-wrapper">
                                    {useDefaultImage ? (
                                        // Render the image with the onError event handler.
                                        <img
                                            src={Zorua}
                                            alt="Default profile image of our cat Zorua."
                                            onError={handleImageError}
                                        />
                                    ) : (
                                        // Display user chosen image when there is no error.
                                        <img src={profileUrl} alt="Profile Pic"/>
                                    )}
                                    <input style={{display: "none"}} type="file" id="select-image" accept="image/*"
                                           onChange={handleChange}/>
                                    <label className="profileImageSelectionIcon" htmlFor="select-image"><TbCameraPlus
                                        size={23}/></label>
                                </div>
                            </div>
                            <div className="edit-profile-account-info">
                                <div className="edit-profile-row">
                                    <div className="edit-profile-column">
                                        <label style={{color: validFirstName ? "black" : "red"}}>First Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={firstName}
                                            onChange={(e) => handleFirstName(e.target.value)}
                                        />
                                    </div>
                                    <div className="edit-profile-column">
                                        <label style={{color: validLastName ? "black" : "red"}}>Last Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={lastName}
                                            onChange={(e) => handleLastName(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="edit-profile-row">
                                    <div className="edit-profile-column">
                                        <label>Seller Bio</label>
                                        <textarea
                                            className="form-control"
                                            value={bio}
                                            onChange={(e) => setBio(e.target.value)}
                                            maxLength={250}
                                        />
                                    </div>
                                </div>
                                <div className="edit-profile-row">
                                    <div className="edit-profile-column">
                                        <label>Business Link</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={shopLink}
                                            onChange={(e) => setShopLink(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="edit-profile-row">
                                    <div className="edit-profile-column">
                                        <label style={{marginBottom: "0px"}}>Social Media</label>
                                        <p style={{marginBottom: "5px"}}>Maximum of three links.</p>

                                        <input className="socialPadding form-control"
                                               type="text"
                                               value={socialMedia1}
                                               onChange={(e) => setSocialMedia1(e.target.value)}
                                        />

                                        <input className="socialPadding form-control"
                                               type="text"
                                               value={socialMedia2}
                                               onChange={(e) => setSocialMedia2(e.target.value)}
                                        />

                                        <input
                                            className="form-control"
                                            type="text"
                                            value={socialMedia3}
                                            onChange={(e) => setSocialMedia3(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="edit-profile-row">
                                    <div className="edit-profile-column">
                                        <label>Email</label>
                                        <input
                                            className="form-control"
                                            disabled={true}
                                            type="text"
                                            value={email}
                                        />
                                    </div>
                                    <div className="edit-profile-column">
                                        <label>Username</label>
                                        <input
                                            className="form-control"
                                            disabled={true}
                                            type="text"
                                            value={username}
                                        />
                                    </div>
                                </div>
                                <div className="edit-profile-row">
                                    <div className="edit-profile-column">
                                        <label>Phone Number</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={phoneNumber}
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                        />
                                    </div>
                                    <div className="edit-profile-column">
                                        <div className="edit-profile-venmo-row">
                                            <label>Venmo Handle</label>
                                            <IconWithTooltip
                                                text={"This gives your buyers the ability to tip you when you complete their order."}/>
                                        </div>

                                        <input
                                            className="form-control"
                                            type="text"
                                            value={venmoHandle}
                                            onChange={(e) => setVenmoHandle(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className="edit-profile-row">
                                    {/*TODO change this classname toggle*/}
                                    <div className="profile-name-toggle">
                                        <label>Display real name to client(s): </label>
                                        <label className="topnav-switch">
                                            <input type="checkbox" defaultChecked={useRealName}
                                                   onChange={changeDisplayRealName}/>
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <ComponentLoading isLoadingComponent={props.isProfileSaving}/>
                                <button className="blueBtn" type="button" hidden={props.isProfileSaving}
                                        style={{width: 'fit-content', margin: '5px 0px 10px'}}
                                        onClick={() => save()}>Save Profile
                                </button>
                            </div>
                            <div className="edit-profile-communication-settings">
                                <label>Referral Link</label>
                                <p>Imagine spreading the word about Comm Comm and earning rewards just for sharing!
                                    Our exciting referral program is designed to thank you for helping others discover
                                    the power of our platform.
                                    When you refer someone new to Comm Comm, you&apos;ll both receive an additional <b>5
                                        free commission cards</b> for your current cycle period!
                                    <a href="https://commcomm.app/faq" className="hyperlink" target="_blank"
                                       rel="noopener noreferrer"> (See FAQ
                                        for more
                                        details)</a></p>
                                <input
                                    className="form-control"
                                    disabled={true}
                                    type="text"
                                    value={referralLink(window.location.origin, username)}
                                    ref={copyReferralLinkRef}
                                />
                                <button className='blueBtn' type="button"
                                        style={{width: 'fit-content', marginTop: '20px'}}
                                        onClick={handleCopyLink}>Copy Link
                                </button>
                            </div>
                            <div className="edit-profile-communication-settings">
                                <label>Comm Comm Email Settings</label>
                                <p>{`Toggle on to receive marketing emails and updates from Comm Comm. Toggle off to unsubscribe 
                                    from marketing emails. You'll still get essential account-related emails.`}</p>
                                <label className="topnav-switch">
                                    <input type="checkbox"/>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <div className="edit-profile-deactivation">
                                <label>Account Deactivation</label>
                                <p>{`Please be aware that account deactivation is temporary, and you can always reactivate
                                    your account by logging back in and clicking on the reactivate button whenever
                                    you're ready.`}</p>
                                {props.accountIsInactive ? <button className='greenBtn' type="button"
                                                                   onClick={() => handleReactivation()}>Reactivate
                                        Account
                                    </button> :
                                    <button className='redBtn' type="button"
                                            onClick={() => handleDeactivationSurveyShow()}>Deactivate Account
                                    </button>}
                            </div>
                        </div>
                        <Modal
                            show={showDeactivationModal}
                            onHide={handleDeactivationSurveyClose}
                            keyboard={false}
                        >
                            <div className={"create-client-popup-background"}>
                                <Modal.Header className="text-center" closeButton>
                                    <Modal.Title className="create-client-header w-100">Help Us Improve!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form id="deactivationFeedbackForm" className="form-design">
                                        <div className='feedbackFormColumnWrapper'>
                                            <p className='formDescriptionText'>{`Please inform us of why you're
                                                deactivating your account by filling out this short,
                                                anonymous form. As a small team, we appreciate your feedback and aim to
                                                ensure that everyone's voice is acknowledged.`}</p>
                                            <div className="innerFeedbackFormColumn">
                                                <label className='create-client-modal-label-subheader-one'
                                                       htmlFor="clientFirstName">
                                                    Reason for deactivation:
                                                    <span style={{color: '#FF3347'}}>*</span></label>
                                                <Form.Select className="generalFormTypeSelection" style={{width: "40%"}}
                                                             aria-label="Select feedback type"
                                                             onChange={(e) => {
                                                                 setDeactivationFeedbackType(e.target.value)
                                                             }}>
                                                    <option value="">Choose a reason</option>
                                                    <option value="1">I found an alternative website/solution.</option>
                                                    <option value="2">{`I'm taking a break from commissions.`}</option>
                                                    <option value="3">{`I'm shutting my business down.`}</option>
                                                    <option value="4">{`I'm rebranding and I want a fresh start with a new
                                                        account.`}
                                                    </option>
                                                    <option value="5">{`I'm not finding the website as useful as I thought
                                                        I would.`}
                                                    </option>
                                                    <option value="6">Other</option>
                                                </Form.Select>
                                            </div>
                                            <div className="innerFeedbackFormColumn" style={{marginBottom: "0px"}}>
                                                <label className='create-client-modal-label-subheader-one'
                                                       htmlFor="clientFirstName">
                                                    Additional Comments:
                                                    <span style={{color: '#FF3347'}}>*</span></label>
                                                <textarea
                                                    className="feedbackDescriptionInput form-control"
                                                    id='feedbackDescription'
                                                    // value={feedback}
                                                    onChange={(e) => {
                                                        setDeactivationComment(e.target.value)
                                                    }}
                                                    maxLength={1000}
                                                    rows={4}
                                                    cols={50}
                                                ></textarea>
                                            </div>
                                            <div className="innerFeedbackFormColumn" style={{marginBottom: "0px"}}>
                                                <button form="feedbackForm"
                                                    // disabled={feedbackType === "" || feedback.trim() === ""} type="button"
                                                        className={deactivationFeedbackType === "" || deactivationComment.trim() === "" ? "disabledBtn" : "redBtn"}
                                                    // className='redBtn'
                                                        onClick={() => handleDeactivationSurveyClose()}>
                                                    Submit & Deactivate
                                                </button>
                                            </div>
                                        </div>
                                    </Form>
                                </Modal.Body>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
            <LoadingOverlay isLoading={props.isAccountLoading}/>
        </>
    );
};

export default EditProfile;