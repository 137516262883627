import React, {useEffect, useState, useRef} from "react";
import {Button, Modal, ModalBody} from "react-bootstrap";
import Toast from 'react-bootstrap/Toast';
import {BsFillCheckCircleFill} from "react-icons/bs";
import {Tag} from "../../../data/Tag";
import CloseIcon from '@mui/icons-material/Close';
import {CardDto} from "../../../data/CardDto";
import {Card} from "../../../data/Card";
import SideRadioPopupOptions from "../../ProjectOverviewBoard/SideRadioPopupOptions";
import {ProjectDetails} from "../../../data/ProjectDetails";
import {ClientDto} from "../../../data/ClientDto";
import SideMultiselectPopupOptions from "../../ProjectOverviewBoard/SideMultiselectPopupOptions";
import SideManageLabelPopupOptions from "../../ProjectOverviewBoard/SideManageLabelPopupOptions";
import {BasicOptions} from "../../../data/BasicOptions";
import {AccountData} from "../../../data/AccountData";
import {CardProgressHistoryDetails} from "../../../data/CardProgressHistoryDetails";
import {AccountSubscriptionPlan, colorPicker, commissionLink, constantList, formatDateTime} from "../../Helpers";
import RichTextEditorComments from "../RichTextEditorComments";
import parse from 'html-react-parser';
import ComponentLoading from './ComponentLoading';
import Zorua from '../../../assets/zorua-profile-pic.jpeg';
import CommCommLogo from '../../../assets/CommCommLogo-Black.png';

import RichTextCommissionNotes from "../RichTextCommissionNotes";

interface IEditCardProps {
    account: AccountData
    projects: ProjectDetails[];
    clients: ClientDto[];
    deleteCard: (cardData: Card) => void;
    card: CardDto;
    columnId: string;
    isOpen: boolean;
    toggleShowCardModal: () => void;
    addTag: (cardData: Card, tag: string, color: string) => void;
    tags: Tag[];
    updateTag: (cardData: CardDto, tagId: string, name: string, color: string) => void;
    addTagOnCard: (cardId: string, tagId: string) => void;
    removeTagOnCard: (cardId: string, tagId: string) => void;
    deleteTag: (tagId: string, projectId: string) => void;
    updateCardTitle: (cardId: string, title: string) => void;
    updateCardQuantity: (cardId: string, count: number) => void;
    updateCardDueDate: (cardId: string, date: string) => void;
    updateCardStartDate: (cardId: string, date: string) => void;
    updateCardColor: (cardId: string, color: string) => void;
    updateCardNotes: (cardId: string, note: string) => void;
    saveClient: (cardData: Card, oldClientId: string) => void;
    saveProject: (cardData: Card, oldProjectId: string) => void;
    moveCardViaButton: (card: CardDto, sourceId: string, destinationId: string) => void;
    updateCardComments: (card: Card, comment: string) => void;
    archiveCommission: (card: CardDto) => void;
    getCardComments: (cardId: string) => void;
    moveCardToDoneColumnViaButton: (card: CardDto) => void
    cardComments: CardProgressHistoryDetails[];
}

//Due Date bug: date from previous card is cached in selection
const EditCard: React.FunctionComponent<IEditCardProps> = (props) => {
    const [title, setTitle] = useState("");
    const [quantity, setQuantity] = useState<number>(0);
    const [tagId, setTagId] = useState<string>("");
    const [tagName, setTagName] = useState<string>("");
    const [tagColor, setTagColor] = useState<string>("");
    const [selectableTags, setSelectableTags] = useState<Tag[]>([]);
    const [notesLength, setNotesLength] = useState(0)
    const [notes, setNotes] = useState("");
    const [commentsLength, setCommentsLength] = useState(0)
    const [comments, setComments] = useState("");
    const [dueDate, setDueDate] = useState("");
    const [startDate, setStartDate] = useState("");
    const [cardTags, setCardTags] = useState<Tag[]>([]);
    const [project, setProject] = useState<ProjectDetails>(props.projects.filter((p) => {
        return p.id === props.card.projectId
    })[0])
    const [clientTag, setClientTag] = useState<Tag>(new Tag());
    const [projectTag, setProjectTag] = useState<Tag>(new Tag());
    const [backgroundColor, setBackgroundColor] = useState("");
    const [isChangeClientShown, setIsChangeClientShown] = useState(false);
    const [isChangeProjectShown, setIsChangeProjectShown] = useState(false);
    const [isManageLabelShown, setIsManageLabelShown] = useState(false);
    const [isCreateLabelShown, setIsCreateLabelShown] = useState(false);
    const [isShareCommissionShown, setIsShareCommissionShown] = useState(false);
    const [isDeleteCommissionShown, setIsDeleteCommissionShown] = useState(false);
    const [isCardMovingToDoneShown, setIsCardMovingToDoneShown] = useState(false);
    const [canMoveCardToDone, setCanMoveCardToDone] = useState(false);
    const [isCardMovingOutOfInQueueShown, setIsCardMovingOutOfInQueueShown] = useState(false);
    const [canMoveCardOutOfInQueue, setCanMoveCardOutOfInQueue] = useState(false);
    const [cardHistoryRecords, setCardHistoryRecords] = useState<CardProgressHistoryDetails[]>([])
    const [showCommentHistory, setShowCommentHistory] = useState<boolean>(false);
    const [showSubmittedComment, setShowSubmittedComment] = useState<boolean>(false);
    const [shareCommissionLink, setShareCommissionLink] = useState("");
    const [showCommentEditor, setShowCommentEditor] = useState<boolean>(false);
    const [disableArchive, setDisableArchive] = useState<boolean>(true);
    const [useDefaultImage, setUseDefaultImage] = useState(true);
    const [loadingComments, setLoadingComments] = useState<boolean>(false);
    const [showNotesSavedConfirmation, setNotesSavedConfirmation] = useState(false);
    const shareLinkRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        setComments("");
        if (props.isOpen) {
            closeMiniPopups();
            setCommentsLength(0);
            setNotesLength(props.card?.notes?.length ?? 0)
            setTitle(props.card?.title ?? "")
            setQuantity(props.card?.quantity ?? 0);
            setNotes(props.card?.notes ?? "");
            setDueDate(props.card?.dueDate ?? "");
            setStartDate(props.card?.startDate ?? "");
            const client = props.clients.filter((c) => {
                return c.id === props.card.clientId
            });
            //Assigns client tag and prevents stale client from staying in
            if (client.length > 0) {
                setClientTag(new Tag({
                    id: client[0].id,
                    name: client[0].firstName + " " + client[0].lastName,
                    color: colorPicker.labelBlue
                }));
            } else {
                setClientTag(new Tag())
            }
            const proj = props.projects.filter((p) => {
                return p.id === props.card.projectId
            });
            //Assigns project tag and prevents stale proejct from staying in
            setProject(proj[0]);
            if (proj.length > 0) {
                setProjectTag(new Tag({id: proj[0].id, name: proj[0].title, color: colorPicker.labelBlue}));
            } else {
                setProjectTag(new Tag())
            }
            setBackgroundColor(props.card?.cardColor ?? "");
            setSelectableTags(props.tags.filter(t => t.projectId === props.card.projectId));
            setShareCommissionLink(commissionLink(window.location.origin, props.card.accountId, props.card.clientId));
            setCardTags(props.card.tagDetails);
            setCardHistoryRecords([]);
            evaluateArchiveButton();
            evaluateMoveColumnButton();
            setLoadingComments(false);
            setShowSubmittedComment(false);
            setShowCommentHistory(false)
        }
    }, [props.isOpen]);

    //this may be unnecessary
    useEffect(() => {
        setSelectableTags(props.tags.filter(t => t.projectId === props.card.projectId))
    }, [props.tags]);

    useEffect(() => {
        if (showCommentHistory && props.card.id !== undefined) {
            props.getCardComments(props.card.id)
            setLoadingComments(true);
            setShowSubmittedComment(false); //Don't need to show only created comments
        }
    }, [showCommentHistory])

    useEffect(() => {
        if (props.cardComments) {
            setLoadingComments(false);
            setCardHistoryRecords(props.cardComments);
        }
    }, [props.cardComments]);

    useEffect(() => {
        //possibily not being updated
        evaluateArchiveButton();
        evaluateMoveColumnButton()
    }, [props.card.columnId]);

    useEffect(() => {
        setDueDate(props.card?.dueDate ?? "");
        evaluateMoveColumnButton()
    }, [props.card.dueDate]);

    useEffect(() => {
        setStartDate(props.card?.startDate ?? "");
        evaluateMoveColumnButton()
    }, [props.card.startDate]);

    useEffect(() => {
        setNotes(props.card.notes);
    }, [props.card.notes]);

    useEffect(() => {
        setTitle(props.card.title);
    }, [props.card.title]);

    useEffect(() => {
        setQuantity(props.card.quantity);
    }, [props.card.quantity]);

    useEffect(() => {
        setBackgroundColor(props.card?.cardColor ?? "");
    }, [props.card.cardColor]);

    useEffect(() => {
        setCardTags(props.card.tagDetails);
    }, [props.card.tagDetails]);

    useEffect(() => {
        const proj = props.projects.filter((p) => {
            return p.id === props.card.projectId
        });
        //Assigns project tag and prevents stale project from staying in
        setProject(proj[0]);
        if (proj.length > 0) {
            setProjectTag(new Tag({id: proj[0].id, name: proj[0].title, color: colorPicker.labelBlue}));
        } else {
            setProjectTag(new Tag())
        }
    }, [props.card.projectId]);

    useEffect(() => {
        const client = props.clients.filter((c) => {
            return c.id === props.card.clientId
        });
        //Assigns client tag and prevents stale client from staying in
        if (client.length > 0) {
            setClientTag(new Tag({
                id: client[0].id,
                name: client[0].firstName + " " + client[0].lastName,
                color: colorPicker.labelBlue
            }));
        } else {
            setClientTag(new Tag())
        }
        evaluateMoveColumnButton()
    }, [props.card.clientId]);

    useEffect(() => {
        setUseDefaultImage(props.account.image === null || props.account.image === "")
    }, [props.account.image])

    const toggleNotesSavedConfirmation = () => setNotesSavedConfirmation(!showNotesSavedConfirmation);

    const evaluateArchiveButton = () => {
        const projIndex = props.projects.findIndex(x => x.id === props.card.projectId);
        if (projIndex >= 0) {
            const lastColumnId = props.projects[projIndex].columnOrder[props.projects[projIndex].columnOrder.length - 1]
            if (lastColumnId && lastColumnId === props.card.columnId) {
                setDisableArchive(false);
            } else {
                setDisableArchive(true);
            }
        }
    }

    const evaluateMoveColumnButton = () => {
        const projIndex = props.projects.findIndex(x => x.id === props.card.projectId);
        if (projIndex >= 0) {
            const firstColumnId = props.projects[projIndex].columnOrder[0]
            //Evaluates if card has everything it needs to move
            if (firstColumnId && firstColumnId === props.card.columnId && (props.card.clientId === "" || props.card.startDate === "" || props.card.dueDate === "")) {
                setCanMoveCardOutOfInQueue(true)
                return;
            }
            const secondToLastColumnId = props.projects[projIndex].columnOrder[props.projects[projIndex].columnOrder.length - 2]
            //Evaluates card to warn user about moving it to done
            if (secondToLastColumnId && secondToLastColumnId === props.card.columnId) {
                setCanMoveCardToDone(true);
                return;
            }
            setCanMoveCardToDone(false);
            setCanMoveCardOutOfInQueue(false);
        }
    }

    if (!props.isOpen) {
        return null;
    }

    const deleteSelection = () => {
        if (props.account.cardDeletesLeft > 0) {
            props.deleteCard(props.card)
            closePopup()
        }
    }

    const show = () => {
        setShowCommentHistory(!showCommentHistory)
    }

    //https://bobbyhadz.com/blog/react-onclick-show-component
    // const handleCardOptionsBeingClicked = (
    //     changeClient: boolean,
    //     changeProject: boolean,
    //     shareCommission: boolean,
    //     manageLabels: boolean,
    //     createLabels:boolean,
    //     deleteCommission: boolean,
    //     cardMovingToDone: boolean,
    //     cardMovingOutOfInQueue: boolean) => {
    //     // 👇️ toggle shown state
    //     setIsChangeClientShown(changeClient);
    //     setIsChangeProjectShown(changeProject);
    //     setIsShareCommissionShown(shareCommission);
    //     setIsManageLabelShown(manageLabels);
    //     setIsCreateLabelShown(createLabels);
    //     setIsDeleteCommissionShown(deleteCommission);
    //     setIsCardMovingToDoneShown(cardMovingToDone)
    //     setIsCardMovingOutOfInQueueShown(cardMovingOutOfInQueue);
    // };
    const handleCardBeingMovedOutOfInQueueClick = () => {
        if (disableArchive) {
            setIsChangeClientShown(false);
            setIsChangeProjectShown(false);
            setIsShareCommissionShown(false);
            setIsManageLabelShown(false);
            setIsCreateLabelShown(false);
            setIsDeleteCommissionShown(false)
            setIsCardMovingToDoneShown(false)
            setIsCardMovingOutOfInQueueShown(current => !current);
        }
    };
    const handleCardBeingMovedToDoneClick = () => {
        if (disableArchive) {
            // 👇️ toggle shown state
            setIsChangeClientShown(false);
            setIsChangeProjectShown(false);
            setIsShareCommissionShown(false);
            setIsManageLabelShown(false);
            setIsCreateLabelShown(false);
            setIsDeleteCommissionShown(false)
            setIsCardMovingToDoneShown(current => !current)
            setIsCardMovingOutOfInQueueShown(false);
        }
    };
    const handleDeleteCommissionClick = () => {
        if (disableArchive) {
            // 👇️ toggle shown state
            setIsChangeClientShown(false);
            setIsChangeProjectShown(false);
            setIsShareCommissionShown(false);
            setIsManageLabelShown(false);
            setIsCreateLabelShown(false);
            setIsDeleteCommissionShown(current => !current);
            setIsCardMovingToDoneShown(false);
            setIsCardMovingOutOfInQueueShown(false);
        }
    };
    const handleChangeClientClick = () => {
        if (disableArchive) {
            // 👇️ toggle shown state
            setIsChangeClientShown(current => !current);
            setIsChangeProjectShown(false);
            setIsShareCommissionShown(false);
            setIsManageLabelShown(false);
            setIsCreateLabelShown(false);
            setIsDeleteCommissionShown(false)
            setIsCardMovingToDoneShown(false);
            setIsCardMovingOutOfInQueueShown(false);
        }
    };

    const handleChangeProjectClick = () => {
        if (disableArchive) {
            // 👇️ toggle shown state
            setIsChangeClientShown(false);
            setIsChangeProjectShown(current => !current);
            setIsShareCommissionShown(false);
            setIsManageLabelShown(false);
            setIsCreateLabelShown(false);
            setIsDeleteCommissionShown(false)
            setIsCardMovingToDoneShown(false);
            setIsCardMovingOutOfInQueueShown(false);
        }
    };

    const handleShareCommissionClick = () => {
        // 👇️ toggle shown state
        setIsChangeClientShown(false);
        setIsChangeProjectShown(false);
        setIsShareCommissionShown(current => !current);
        setIsManageLabelShown(false);
        setIsCreateLabelShown(false);
        setIsDeleteCommissionShown(false)
        setIsCardMovingToDoneShown(false);
        setIsCardMovingOutOfInQueueShown(false);
    };

    const handleManageLabelClick = () => {
        // 👇️ toggle shown state
        setIsChangeClientShown(false);
        setIsChangeProjectShown(false);
        setIsShareCommissionShown(false);
        setIsManageLabelShown(current => !current);
        setIsCreateLabelShown(false);
        setIsDeleteCommissionShown(false)
        setIsCardMovingToDoneShown(false);
        setIsCardMovingOutOfInQueueShown(false);
    };

    const handleCreateLabelClick = () => {
        // 👇️ toggle shown state
        setTagName("");
        setTagColor("#DB2828");
        setIsChangeClientShown(false);
        setIsChangeProjectShown(false);
        setIsShareCommissionShown(false);
        setIsManageLabelShown(false);
        setIsCreateLabelShown(current => !current);
        setIsDeleteCommissionShown(false)
        setIsCardMovingToDoneShown(false);
        setIsCardMovingOutOfInQueueShown(false);
    };

    const handleEditLabelClick = (label: string, color: string, id: string) => {
        // 👇️ toggle shown state
        setTagId(id)
        setTagName(label);
        setTagColor(color);
        setIsChangeClientShown(false);
        setIsChangeProjectShown(false);
        setIsShareCommissionShown(false);
        setIsManageLabelShown(false);
        setIsCreateLabelShown(current => !current);
        setIsDeleteCommissionShown(false)
        setIsCardMovingToDoneShown(false);
        setIsCardMovingOutOfInQueueShown(false);
    };

    const closePopup = () => {
        closeMiniPopups();
        props.toggleShowCardModal();
    }

    const closeMiniPopups = () => {
        setIsChangeClientShown(false);
        setIsChangeProjectShown(false);
        setIsShareCommissionShown(false);
        setIsManageLabelShown(false);
        setIsCreateLabelShown(false);
        setIsDeleteCommissionShown(false);
        setShowCommentEditor(false);
        setShowCommentHistory(false);
        setIsCardMovingToDoneShown(false);
        setIsCardMovingOutOfInQueueShown(false);
        setNotesSavedConfirmation(false);
    }

    //Close modal incase they are on client view and change client
    const saveClient = (client: string) => {
        props.saveClient({...props.card as Card, clientId: client}, props.card.clientId);
        closePopup();
    }

    //Close modal incase they are on project view and change project
    const saveProject = (project: string) => {
        props.saveProject({...props.card as Card, projectId: project}, props.card.projectId);
        closePopup()
    }

    const saveLabel = (label: string, color: string) => {
        props.addTag(props.card as Card, label, color);
    }

    const addTagsOnCard = (tagId: string) => {
        props.addTagOnCard(props.card.id, tagId);
    }

    const removeTagsOnCard = (tagId: string) => {
        props.removeTagOnCard(props.card.id, tagId);
    }

    const updateLabel = (tagId: string, name: string, color: string) => {
        props.updateTag(props.card, tagId, name, color);
    }

    const deleteLabel = (tagId: string) => {
        props.deleteTag(tagId, props.card.projectId);
    }

    //Moves card to next column
    const moveToNextColumn = () => {
        if (project.columnOrder[project.columnOrder.length - 1] !== props.columnId) {
            const currColIndex = project.columnOrder.findIndex(c => c === props.columnId);
            props.moveCardViaButton(props.card, props.columnId, project.columnOrder[currColIndex + 1]);
            closeMiniPopups();
        }
    }

    const moveToDoneColumn = () => {
        if (project.columnOrder[project.columnOrder.length - 1] !== props.columnId) {
            props.moveCardToDoneColumnViaButton(props.card)
            closeMiniPopups();
        }
    }

    //Evaluate if card can be moved or warning needs to pop up
    const moveToNextColumnEvaluation = () => {
        if (!disableArchive) {
            return;
        }
        if (canMoveCardOutOfInQueue) {
            handleCardBeingMovedOutOfInQueueClick()
        } else if (canMoveCardToDone) {
            handleCardBeingMovedToDoneClick()
        } else {
            moveToNextColumn()
        }
    }

    const saveTitle = () => {
        if (title !== props.card.title && title !== "") {
            props.updateCardTitle(props.card.id, title);
        }
    }

    const saveQuantity = () => {
        if (quantity !== props.card.quantity) {
            props.updateCardQuantity(props.card.id, quantity);
        }
    }

    const saveDueDate = () => {
        if (dueDate !== props.card.dueDate) {
            props.updateCardDueDate(props.card.id, dueDate);
        }
    }

    const saveStartDate = () => {
        if (startDate !== props.card.startDate) {
            props.updateCardStartDate(props.card.id, startDate);
        }
    }

    const saveColor = (color: string) => {
        if (color !== props.card.cardColor) {
            setBackgroundColor(color);
            props.updateCardColor(props.card.id, color);
        } else {
            // If the selected color is the same as the current color,
            setBackgroundColor(color);
            props.updateCardColor(props.card.id, color);
        }
    }

    const saveNotes = () => {
        if (notes !== props.card.notes && notesLength <= constantList.richTextMaxLength) {
            props.updateCardNotes(props.card.id, notes);
            setNotesSavedConfirmation(true);
            setTimeout(function () {
                setNotesSavedConfirmation(false);
            }, 4000);
        }
    }

    const saveComments = () => {
        if (comments !== "" && commentsLength <= constantList.richTextMaxLength) {
            props.updateCardComments(props.card, comments);
            setComments("");
            setCommentsLength(0)
            setShowCommentEditor(false);
            setShowSubmittedComment(true);
            setLoadingComments(true);
            //show comment at the bottom
            // setShowDetails(true);
        }
    }

    const archiveCard = () => {
        if (props.card.completedDate !== "") {
            props.archiveCommission(props.card)
            closePopup()
        }
    }

    const handleImageError = () => {
        // Handle the image loading error here, e.g., set a state variable to indicate the error.
        setUseDefaultImage(false);
    };

    const clientBtnText = clientTag.name === '' ? 'Add Client' : 'Change Client';

    const handleCopyLink = () => {
        if (shareLinkRef.current) {
            shareLinkRef.current.select();
            document.execCommand('copy');

            // As document.execCommand is deprecated, using the Clipboard API as a fallback
            try {
                navigator.clipboard.writeText(shareLinkRef.current.value);
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
            }
        }
    };

    return (
        <div key={props.card.id}>
            {props.isOpen && <Modal size="lg"
                                    show={props.isOpen}
                                    onHide={closePopup}>
                <ModalBody className="edit-card-popup-background">
                    <Toast show={showNotesSavedConfirmation} onClose={toggleNotesSavedConfirmation}>
                        <Toast.Header closeButton aria-label="Hide" closeVariant="white">
                            <BsFillCheckCircleFill size={23} color={colorPicker.confirmationGreen}/>
                            <strong className="savedConfirmationText">Your commission notes have been saved!</strong>
                        </Toast.Header>
                    </Toast>
                    <form id='editCommission' className="editCardForm">
                        <div className="editCardStandardRow" style={{marginBottom: '0px'}}>
                            <div className="commissionNameColumn">
                                <label className="editFormLabel"
                                       htmlFor='commission-name'>
                                    Commission Name
                                </label>

                                <input className="commissionNameInput"
                                       id='commission-name'
                                       type='text'
                                       value={title ?? ''}
                                       onChange={(e) => {
                                           setTitle(e.target.value)
                                       }}
                                       onBlur={() => saveTitle()}
                                />
                            </div>

                            <div className="commissionQuantityColumn">
                                <label className="editFormLabel"
                                       htmlFor='commission-quantity'>
                                    Quantity
                                </label>

                                <input className="quantityInput"
                                       id='commission-quantity'
                                       type='number'
                                       value={quantity ?? 1}
                                       onChange={(e) => {
                                           setQuantity(parseInt(e.target.value))
                                       }}
                                       onBlur={() => saveQuantity()}
                                />
                            </div>

                            <div className="commissionCloseBtn">
                                <div style={{padding: "0px 0px 5px 5px"}} onClick={closePopup}><CloseIcon/></div>
                            </div>
                        </div>

                        <div className="editCardStandardRow" style={{columnGap: '25px'}}>
                            <div className="editCardMainBodyColumn">
                                <div className="editCardStandardRow">
                                    <div className="tagColumn">
                                        <label className="editFormLabel"
                                               htmlFor='commission-client'>
                                            Client<span style={{color: '#FF3347'}}>*</span>
                                        </label>

                                        <p className="card-label-tag" style={{
                                            backgroundColor: clientTag.color,
                                            color: colorPicker.white,
                                            marginBottom: '5px'
                                        }}>
                                            {clientTag.name}
                                        </p>
                                    </div>

                                    <div className="tagColumn">
                                        <label className="editFormLabel"
                                               htmlFor='commission-project'>
                                            Project
                                        </label>

                                        <p className="card-label-tag" style={{
                                            backgroundColor: projectTag.color,
                                            color: colorPicker.white,
                                            marginBottom: '5px'
                                        }}>
                                            {projectTag.name}
                                        </p>
                                    </div>

                                    <div className="tagColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Labels'>
                                            Labels
                                        </label>
                                        <div className="moreLabelsRow">
                                            {cardTags && cardTags.map((item, index) => (
                                                <p key={item.id} className="card-label-tag" style={{
                                                    backgroundColor: item.color,
                                                    color: colorPicker.white,
                                                    marginBottom: '5px'
                                                }}>
                                                    {item.name}</p>
                                            ))}
                                        </div>
                                    </div>

                                    {/*<div className="tagColumn" style={{marginBottom:'5px'}}>*/}
                                    {/*    <label className="editFormLabel"*/}
                                    {/*        htmlFor='Labels'>*/}
                                    {/*        Add Label*/}
                                    {/*    </label>*/}
                                    {/*    <div className="newLabelCreationRow">*/}
                                    {/*        <MdAddCircle style={{fontSize: "30px", color: "#F171AB"}}/>*/}
                                    {/*        <input className="labelNameInput"*/}
                                    {/*            id='commission-addTag'*/}
                                    {/*            type='text'*/}
                                    {/*            value={tagName}*/}
                                    {/*            onChange={(e) => {*/}
                                    {/*                setTagName(e.target.value)*/}
                                    {/*            }}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>

                                <div className="editCardStandardRow">
                                    <div className="editCardStandardColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Start Date'>
                                            Start Date<span style={{color: '#FF3347'}}>*</span>
                                        </label>

                                        <input className="dateInput"
                                               id='startDate'
                                               type='date'
                                               value={startDate}
                                               min='2023-01-01'
                                               onChange={(e) => {
                                                   setStartDate(e.target.value)
                                               }}
                                               onBlur={() => saveStartDate()}
                                        />
                                    </div>
                                    <div className="editCardStandardColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Due Date'>
                                            Due Date<span style={{color: '#FF3347'}}>*</span>
                                        </label>

                                        <input className="dateInput"
                                               id='dueDate'
                                               type='date'
                                               value={dueDate}
                                               min={new Date().toISOString().slice(0, 10)}
                                               onChange={(e) => {
                                                   setDueDate(e.target.value)
                                               }}
                                               onBlur={() => saveDueDate()}
                                        />
                                    </div>

                                    <div className="editCardStandardColumn">
                                        <label className="editFormLabel"
                                               htmlFor='Card Color'>
                                            Commission Color
                                        </label>

                                        <div className="flex-bg-color-row">
                                            <div onClick={() => saveColor('#FFF69B')}>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#FFF69B',
                                                    border: backgroundColor === '#FFF69B' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div onClick={() => saveColor('#F6C2D9')}>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#F6C2D9',
                                                    border: backgroundColor === '#F6C2D9' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div onClick={() => saveColor('#E4DAE2')}>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#E4DAE2',
                                                    border: backgroundColor === '#E4DAE2' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div onClick={() => saveColor('#BCDFC9')}>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#BCDFC9',
                                                    border: backgroundColor === '#BCDFC9' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                            <div onClick={() => saveColor('#ABCEEB')}>
                                                <div className="card-color-choice" style={{
                                                    backgroundColor: '#ABCEEB',
                                                    border: backgroundColor === '#ABCEEB' ? '3px solid #F171AB' : '1px solid #A9A9A9'
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="notesColumn">
                                    <label className="editFormLabel"
                                           htmlFor='Description'>
                                        Commission Notes
                                    </label>
                                    <p className="featureNote">{props.account.acctSubscriptionPlan > AccountSubscriptionPlan.Basic ?
                                        "Notes are not visible to the client. Image Copy and Pasting is not support." :
                                        "Notes are not visible to the client."}</p>
                                    <div>
                                        <RichTextCommissionNotes notes={notes} setNotes={setNotes}
                                                                 notesLength={notesLength}
                                                                 active={true}
                                                                 acctSubscription={props.account.acctSubscriptionPlan}
                                                                 setNotesLength={setNotesLength}/>
                                    </div>
                                    <button className="blueBtn" type="button"
                                            style={{width: '120px', margin: '10px 0px'}}
                                            onClick={() => saveNotes()}>
                                        Save Notes
                                    </button>
                                </div>

                                <div className="commentInfoRow">
                                    <div className="editCardStandardColumn"
                                         style={{flex: '3 1 1', marginRight: '13px'}}>
                                        <label className="editFormLabel"
                                               htmlFor='Comments'>
                                            Commission Comments
                                        </label>
                                        <p className="featureNote">Comments and picture attachments are viewable by the
                                            client.</p>
                                    </div>

                                    <button className="blueBtn" style={{flex: '1'}} type="button"
                                            onClick={() => show()}>
                                        {showCommentHistory ? "Hide Details" : "Show Details"}
                                    </button>
                                    {/* Make the show details change to hide details when everything is revealed. */}
                                </div>

                                <div className="editCardStandardRow">
                                    {useDefaultImage ? (
                                        // Render the image with the onError event handler.
                                        <img
                                            className="comment-profile-image"
                                            src={Zorua}
                                            alt="Default profile image of our cat Zorua."
                                            onError={handleImageError}
                                        />
                                    ) : (
                                        // Display user chosen image when there is no error.
                                        <img
                                            className="comment-profile-image"
                                            src={props.account.image}
                                            alt="Account profile image."
                                        />
                                    )}
                                    <div className="newArtistCommentInputAfter" hidden={!showCommentEditor}>
                                        <RichTextEditorComments onFocus={showCommentEditor} comments={comments}
                                                                setCommentsLength={setCommentsLength}
                                                                acctSubscription={props.account.acctSubscriptionPlan}
                                                                setComments={setComments}/>
                                    </div>

                                    <input type="text" className="newArtistCommentInputBefore"
                                           id='newComment'
                                           maxLength={500}
                                           hidden={showCommentEditor}
                                           onClick={() => setShowCommentEditor(true)}
                                    />
                                </div>

                                <button className="blueBtn" type="button" style={{width: '140px'}}
                                        onClick={() => saveComments()}>
                                    Save Comment
                                </button>
                                <ComponentLoading isLoadingComponent={loadingComments}/>
                                {cardHistoryRecords && <div
                                    style={{
                                        display: showCommentHistory || showSubmittedComment ? "block" : "none",
                                        maxHeight: "300px",
                                        overflow: "auto"
                                    }}>
                                    {cardHistoryRecords.map((details, index) => {
                                        return (
                                            <div key={index} className="history-details-container">
                                                <img
                                                    className="history-profile-image"
                                                    src={details.profileUrl === null || details.profileUrl === "" ? CommCommLogo : details.profileUrl}
                                                    alt="Account profile image."
                                                />
                                                <div className="history-details">
                                                    <span
                                                        className='history-author'>{details.editor}</span> {parse(details.detailText)}
                                                    <div className='history-date'>
                                                        {formatDateTime(details.createdAt)}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>}
                            </div>

                            {/* 👇️ show elements on click */}
                            {isChangeClientShown && (
                                <SideRadioPopupOptions
                                    original={new BasicOptions(clientTag.id, clientTag.name)}
                                    title={"Clients"}
                                    warningText={"Changing the client will close the whole card to update the commission."}
                                    closePopup={handleChangeClientClick}
                                    saveText={"Save Client"}
                                    list={props.clients && props.clients.map((client: ClientDto) => {
                                        return new BasicOptions(client.id, client.firstName + " " + client.lastName)
                                    })}
                                    saveOption={saveClient}/>
                            )}

                            {isChangeProjectShown && (
                                <SideRadioPopupOptions
                                    original={new BasicOptions(projectTag.id, projectTag.name)}
                                    title={"Projects"}
                                    warningText={"Changing the project will close the whole card to update the commission."}
                                    closePopup={handleChangeProjectClick}
                                    saveText={"Save Project"}
                                    list={props.projects && props.projects.map((project: ProjectDetails) => {
                                        return new BasicOptions(project.id, project.title)
                                    })}
                                    saveOption={saveProject}/>
                            )}
                            {isManageLabelShown &&
                                (<SideMultiselectPopupOptions
                                        title={"Custom Labels"}
                                        saveText={"Update Labels"}
                                        tagList={selectableTags}
                                        selectedTagList={props.card.tags}
                                        openCreateLabelPopup={handleCreateLabelClick}
                                        openEditLabelPopup={handleEditLabelClick}
                                        closePopup={handleManageLabelClick}
                                        selectOption={addTagsOnCard}
                                        unSelectOption={removeTagsOnCard}
                                    />
                                )}
                            {isCreateLabelShown &&
                                (<SideManageLabelPopupOptions
                                        tagId={tagId}
                                        editText={tagName}
                                        editColor={tagColor}
                                        previousPopUp={handleManageLabelClick}
                                        closePopup={handleCreateLabelClick}
                                        saveLabel={saveLabel}
                                        deleteLabel={deleteLabel}
                                        updateLabel={updateLabel}
                                    />
                                )}
                            {isShareCommissionShown && (
                                <div className="sideActionBtnPopup">
                                    <div className="sideBtnPopupHeaderNoSearch">
                                        <h4>Share Link</h4>
                                        <div style={{padding: "0px 0px 5px 5px"}} onClick={handleShareCommissionClick}>
                                            <CloseIcon style={{cursor: "pointer"}}/></div>
                                    </div>
                                    <label className="shareLinkLabel" htmlFor='Comments'>
                                        Link to this commission:
                                    </label>
                                    {/*change input action*/}
                                    <input
                                        type="client"
                                        className="form-control"
                                        style={{marginBottom: '10px'}}
                                        id="exampleInputPassword1"
                                        value={shareCommissionLink}
                                        placeholder="CommComm Link..."
                                        ref={shareLinkRef}
                                    />
                                    <button
                                        className="blueBtn"
                                        type="button"
                                        style={{width: '100%', margin: '5px 0px 10px'}}
                                        onClick={handleCopyLink}>
                                        Copy Link
                                    </button>
                                </div>
                            )}

                            {isDeleteCommissionShown && (
                                <div className="sideActionBtnPopup">
                                    <div className="sideBtnPopupHeaderNoSearch">
                                        <h4>Deletion Warning</h4>
                                        <div style={{padding: "0px 0px 5px 5px"}} onClick={handleDeleteCommissionClick}>
                                            <CloseIcon style={{cursor: "pointer"}}/></div>
                                    </div>
                                    <p>{`This will permanently remove the commission from your project board. There is no
                                        undo.
                                        Deleted commissions will not count against your plan's allotted commissions.
                                        Your allocated deletes will reset every year. Only available on non-free account tiers. `}<span
                                        style={{color: '#FF3347', fontWeight: "bold"}}>You
                                        have {props.account.cardDeletesLeft} deletes left.</span>
                                    </p>
                                    <button className="redBtn"
                                            type="button"
                                            style={{width: '100%', margin: '10px 0px'}}
                                            onClick={() => deleteSelection()}>
                                        Delete Commission
                                    </button>
                                </div>
                            )}

                            {isCardMovingToDoneShown && (
                                <div className="sideActionBtnPopup">
                                    <div className="sideBtnPopupHeaderNoSearch">
                                        <h4>Moving Commission to Done</h4>
                                        <div style={{padding: "0px 0px 5px 5px"}}
                                             onClick={handleCardBeingMovedToDoneClick}>
                                            <CloseIcon style={{cursor: "pointer"}}/></div>
                                    </div>
                                    <p>Once you move this commission to <b>Done</b>, you <span
                                        style={{color: '#FF3347', fontWeight: "bold"}}>WILL NOT </span>
                                        be able to move it to a previous column anymore. Make sure all tasks are
                                        done before completing this commission.
                                    </p>
                                    <button className="blueBtn"
                                            type="button"
                                            style={{width: '100%', margin: '10px 0px'}}
                                            onClick={() => moveToDoneColumn()}>
                                        Move to Done Column
                                    </button>
                                </div>
                            )}

                            {isCardMovingOutOfInQueueShown && (
                                <div className="sideActionBtnPopup">
                                    <div className="sideBtnPopupHeaderNoSearch">
                                        <h4>The Commission Is Missing Important Fields!</h4>
                                        <div style={{padding: "0px 0px 5px 5px"}}
                                             onClick={handleCardBeingMovedOutOfInQueueClick}>
                                            <CloseIcon style={{cursor: "pointer"}}/></div>
                                    </div>
                                    <p>Your commission is missing a <span
                                        style={{color: '#FF3347', fontWeight: "bold"}}>Start Date</span>,
                                        <span style={{
                                            color: '#FF3347',
                                            fontWeight: "bold"
                                        }}> Due Date</span> and/or <span
                                            style={{color: '#FF3347', fontWeight: "bold"}}>Client</span>.
                                        Please assign a value to each missing field(s).
                                    </p>
                                    <button className="blueBtn"
                                            type="button"
                                            style={{width: '100%', margin: '10px 0px'}}
                                            onClick={() => handleCardBeingMovedOutOfInQueueClick()}>
                                        Close
                                    </button>
                                </div>
                            )}

                            <div className="sideActionColumn">
                                <div className="sideActionColumnInnerRow">
                                    <div className="editCardStandardColumn">
                                        <label className="editSideActionLabel"
                                               htmlFor='Description'>
                                            Edit Card
                                        </label>
                                        <Button disabled={!disableArchive}
                                                type="button"
                                                className="black-outline-btn" style={{width: '180px'}}
                                                onClick={handleChangeClientClick}>
                                            {clientBtnText}
                                        </Button>
                                        <Button disabled={!disableArchive}
                                                className="black-outline-btn" type="button" style={{width: '180px'}}
                                                onClick={handleChangeProjectClick}>
                                            Change Project
                                        </Button>
                                        {/* <Button className="black-outline-btn" type="button" style={{width: '180px'}}>
                                            Change Worker
                                        </Button> */}
                                        <Button className="black-outline-btn" type="button" style={{width: '180px'}}
                                                onClick={handleManageLabelClick}>
                                            Manage Labels
                                        </Button>
                                    </div>
                                    <div className="editCardStandardColumn">
                                        <label className="editSideActionLabel"
                                               htmlFor='Description'>
                                            Actions
                                        </label>
                                        <Button className="black-outline-btn" style={{width: '180px'}}
                                                type="button"
                                                onClick={handleShareCommissionClick}>
                                            Share Commission
                                        </Button>
                                        <Button disabled={!disableArchive}
                                                className="black-outline-btn" style={{width: '180px'}}
                                                onClick={moveToNextColumnEvaluation}>
                                            Move to Next Column
                                        </Button>
                                        <Button disabled={!disableArchive}
                                                type="button"
                                                className="black-outline-btn" style={{width: '180px'}}
                                                onClick={handleDeleteCommissionClick}>
                                            Delete
                                        </Button>
                                        <Button className="black-outline-btn" type="button" style={{width: '180px'}}
                                                disabled={disableArchive}
                                                onClick={() => archiveCard()}>
                                            Archive
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>}
        </div>
    );
}

export default EditCard
